import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";
import {ConfType} from "../contexts/conf";
import {PackHeures} from "../models/PackHeures";
import {VE_TETE_DOCUM} from "../models/VE_TETE_DOCUM";
import {VE_PIED_DOCUM} from "../models/VE_PIED_DOCUM";

export const GetPacksHeuresDatasource = (conf: ConfType, codeSociete: number, fromDate: Date, toDate: Date) => new DataSource({
    store: new ODataStore({
        version: 4,
        withCredentials: false,
        url: conf.oasisApiUrl + "OdataTetesDocumentsVente/GetPacksHeures",
        key: PackHeures.Id,
        beforeSend: (request) => {
            const token: string | null = localStorage.getItem('oasisWebUser') || sessionStorage.getItem('oasisWebUser');

            if (token) {
                request.headers["Authorization"] = "Bearer " + token;
            }
        }
    }),
    select: [
        PackHeures.Id,
        PackHeures.AccompteTtc,
        PackHeures.CommandeTtc,
        PackHeures.FactureTtc,
        PackHeures.SoldeTtc,
        PackHeures.Status,
        `${PackHeures.VeTeteDocumAccompte}.${VE_TETE_DOCUM.RAISON_SOCIALE_NOM}`,
        `${PackHeures.VeTeteDocumAccompte}.${VE_TETE_DOCUM.NO_DOCUMENT}`,
        `${PackHeures.VeTeteDocumAccompte}.${VE_TETE_DOCUM.DATE_DOCUMENT}`,
        `${PackHeures.VeTeteDocumAccompte}.${VE_TETE_DOCUM.DATE_DOCUMENTDateTime}`,
        `${PackHeures.VeTeteDocumAccompte}.${VE_TETE_DOCUM.CONCERNE}`,
        `${PackHeures.VePiedsDocumDeductionCommandes}.${VE_PIED_DOCUM.NO_DOCUMENT}`,
        `${PackHeures.VePiedsDocumDeductionCommandes}.${VE_PIED_DOCUM.MONTANT_LIGNE_PIED_TTC}`,
        `${PackHeures.VePiedsDocumDeductionCommandes}.${VE_PIED_DOCUM.ParentVE_TETE_DOCUM}.${VE_TETE_DOCUM.DATE_DOCUMENTDateTime}`,
        `${PackHeures.VePiedsDocumDeductionFactures}.${VE_PIED_DOCUM.NO_DOCUMENT}`,
        `${PackHeures.VePiedsDocumDeductionFactures}.${VE_PIED_DOCUM.MONTANT_LIGNE_PIED_TTC}`,
        `${PackHeures.VePiedsDocumDeductionFactures}.${VE_PIED_DOCUM.ParentVE_TETE_DOCUM}.${VE_TETE_DOCUM.DATE_DOCUMENTDateTime}`,
    ],
    expand: [`${PackHeures.VePiedsDocumDeductionCommandes}, ${PackHeures.VePiedsDocumDeductionFactures}`],
    customQueryParams: {
        CodeSociete: codeSociete,
        fromDate: fromDate.toJSON(),
        toDate: toDate.toJSON()
    }

});
